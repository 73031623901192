import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Popup from "../../components/Popup/Popup";
import Header from "../../atoms/Header";
import ValidationNewPassword from "../../utils/ValidationNewPassword";
import Input from "../../atoms/Input";
import Button from "../../atoms/Button";
import KeyIcon from "../../img/icon_button/pass.png";
import axiosInstance from "../../utils/axiosConfig";

const EnterNewPassword = () => {
  const { id, token } = useParams();
  const headers = {
    "Content-Type": "application/json",
  };
  const [values, setValues] = useState({
    password: "",
    confirm_password: "",
    id: id,
    token: token,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [errors, setErrors] = useState({});
  const handleNewPassword = async () => {
    try {
      console.log(values);
      const newPassword = {
        password: values.password,
        id: values.id,
        token: values.token,
      };
      const response = await axiosInstance.post(
        "change_password",
        newPassword,
        {
          headers,
        }
      );
      console.log("Account created:", response.data);
      if (response.data.status === true) {
        setStatus(true);
      } else if (response.data.status === false) {
        setStatus(false);
      }
      togglePopup();
    } catch (error) {
      console.error("Error creating account:", error);
      setStatus(false);
      togglePopup();
    }
  };
  const handleInput = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  function handleValidation(e) {
    e.preventDefault();
    const validationErrors = ValidationNewPassword(values);
    setErrors(validationErrors);
    const noErrors = Object.keys(validationErrors).length === 0;
    if (noErrors) {
      handleNewPassword();
    }
  }
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const navigate = useNavigate();
  const login = () => {
    navigate("/login");
  };
  return (
    <div className="enter-new-password-page">
      <Header text="確認密碼" />
      {id && token && (
        <div>
          <h2>
            ID: {id} token: {token}
          </h2>
        </div>
      )}

      <form
        className="register-form inputfields-wrapper"
        onSubmit={handleValidation}
      >
        {/* <label htmlFor="password">*密碼</label> */}
        {/* <input
          value={values.password}
          onChange={handleInput}
          type="password"
          placeholder="********"
          id="password"
          name="password"
        />
        {errors.password && <p className="error-message">{errors.password}</p>}
        <label htmlFor="confirm_password">*再次輸入密碼</label>
        <input
          value={values.confirm_password}
          onChange={handleInput}
          type="password"
          placeholder="********"
          id="confirm_password"
          name="confirm_password"
        />
        {errors.confirm_password && (
          <p className="error-message">{errors.confirm_password}</p>
        )} */}
        <div style={{ width: "100%" }}>
          <div
            className={`position-relative${
              errors.password ? " margin-bottom" : ""
            }`}
          >
            <Input
              value={values.password}
              onChange={handleInput}
              type="password"
              name="password"
              image={KeyIcon}
              isPassword
              label="請輸入您的新密碼"
              isRequired
            />
            {errors.password && (
              <p className="error-message">{errors.password}</p>
            )}
          </div>
          <div
            className={`position-relative${
              errors.confirm_password ? " margin-bottom" : ""
            }`}
          >
            <Input
              value={values.confirm_password}
              onChange={handleInput}
              type="password"
              name="confirm_password"
              image={KeyIcon}
              isPassword
              isRequired
              label="請重複輸入您的新密碼"
            />
            {errors.confirm_password && (
              <p className="error-message">{errors.confirm_password}</p>
            )}
          </div>
        </div>

        <Button text="註冊" />
      </form>

      {isOpen && (
        <Popup
          content={
            <>
              <b>更改密碼連網頁連結已寄至信箱</b>
              <button onClick={login}>OK</button>
            </>
          }
          handleClose={togglePopup}
        />
      )}
    </div>
  );
};

export default EnterNewPassword;

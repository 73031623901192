function ValidationEmail(values) {
  console.log("validation values" + values);
  let error = "";
  const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (values === "") {
    error = "*此欄為必填欄位";
  } else if (!email_pattern.test(values)) {
    error = "Email Didn't match";
  }
  return error;
}

export default ValidationEmail;

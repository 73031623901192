import React from "react";

const Header = (props) => {
  const { text } = props;

  return (
    <div className="header">
      <h3>{text}</h3>
    </div>
  );
};

export default Header;

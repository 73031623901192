import React from "react";

const Button = (props) => {
  const { onClick, text, isTransparent = false, type = "button" } = props;
  const inputStyle = {
    backgroundColor: isTransparent ? "#ffffff" : "#119963",
    color: isTransparent ? "#119963" : "#ffffff",
  };
  return (
    <button
      type={type}
      onClick={onClick}
      className="custom-button"
      style={inputStyle}
    >
      {text}
    </button>
  );
};

export default Button;

import Google from "../img/icon_button/google.png";
import Facebook from "../img/icon_button/facebook.png";
import Line from "../img/icon_button/line.png";
import GolfLogo from "../img/icon_button/Component 13.png";
import GolfLogoIpad from "../img/ipad_icon.png";
import Input from "../atoms/Input";
import Button from "../atoms/Button";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GolfBallIcon from "../img/account.png";
import KeyIcon from "../img/icon_button/pass.png";
import axiosInstance from "../utils/axiosConfig";
import { useDispatch } from "react-redux";
import Popup from "../components/Popup/Popup";
import SuccessIcon from "../img/icon_button/ok.png";
import ErrorIcon from "../img/icon_button/no.png";
import ValidationLogin from "../utils/ValidationLogin";

const Login = () => {
  //const LOGIN_URL = "https://server-dot-golfgameproject.uc.r.appspot.com";
  const LOGIN_URL = "https://itrigolfgame.idv.tw/api-login";
  const { id } = useParams();
  const dispatch = useDispatch();
  const storedUser = JSON.parse(sessionStorage.getItem("user"));
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [errors, setErrors] = useState({});
  const [userData, setUserData] = useState(
    sessionStorage.getItem("user")
      ? storedUser
      : {
          id: "",
          name: "",
          social_media: "",
        }
  );
  useEffect(() => {
    console.log("aaa " + LOGIN_URL);
    sessionStorage.setItem("device_id", id);
    console.log("useeffect", userData);
    getUser();
  }, [userData]);
  const getUser = async () => {
    await fetch(`${LOGIN_URL}/auth/login/success`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error("authentication has been failed!");
      })
      .then(async (resObject) => {
        console.log("success" + resObject);
        const newUser = {
          id: resObject.user.id,
          name: resObject.user.displayName,
          social_media: resObject.user.provider,
        };

        if (userData.id !== newUser.id) {
          console.log("not the same");
          handleThirdPartyLogin(newUser);
          sessionStorage.setItem("user", JSON.stringify(newUser));
          await fetch(`${LOGIN_URL}/auth/logout`, {
            method: "GET",
            credentials: "include",
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.json();
            })
            .then((data) => {
              console.log("Logout response: ", data);
            })
            .catch((error) => {
              console.error("Fetch error: ", error);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleThirdPartyLogin = async (newUser) => {
    console.log("user ", newUser);
    try {
      dispatch({ type: "SET_LOADING", payload: true });

      const response = await axiosInstance.post("social_media_login", newUser, {
        headers,
      });
      // sessionStorage.removeItem("user");
      console.log("Item created:", response.data);
      // window.open("http://localhost:5000/auth/logout", "_self");
      dispatch({ type: "SET_LOADING", payload: false });

      if (response.data.status === true) {
        setStatus(true);
      } else if (response.data.status === false) {
        setStatus(false);
        // window.open("http://localhost:5000/auth/logout", "_self");
      }

      togglePopup();
    } catch (error) {
      console.error("Error creating item:", error);
      setStatus(false);
      togglePopup();
    }
  };
  const togglePopup = () => {
    setIsOpen(!isOpen);
    setValues({
      email: "",
      password: "",
      device_id: id,
    });
    // setUserData()
  };
  const fetchData = async () => {
    dispatch({ type: "SET_LOADING", payload: true });
    await axiosInstance
      .get("get_account/2c9e7cd2e5e4")
      .then((response) => {
        console.log(response);
        dispatch({ type: "SET_LOADING", payload: false });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const google = () => {
    window.open(`${LOGIN_URL}/auth/google`, "_self");
  };

  const facebook = () => {
    window.open(`${LOGIN_URL}/auth/facebook`, "_self");
  };

  const line = () => {
    window.open(`${LOGIN_URL}/auth/line`, "_self");
  };
  const headers = {
    "Content-Type": "application/json",
  };
  const navigate = useNavigate();
  const handleLogin = async () => {
    try {
      console.log(values);
      const response = await axiosInstance.post("account_login", values, {
        headers,
      });
      console.log("Item created:", response.data);
      if (response.data.status === true) {
        setStatus(true);
      } else if (response.data.status === false) {
        setStatus(false);
      }
      togglePopup();
    } catch (error) {
      console.error("Error creating item:", error);
      setStatus(false);
      togglePopup();
    }
  };
  const navigateLogin = () => {
    navigate(`/login/${id}`);
    togglePopup();
  };
  const navigateSignUp = () => {
    navigate("/signup");
  };
  const navigateForgotPassword = () => {
    navigate("/forgotpassword");
  };
  const navigateNewPassword = () => {
    navigate("/newpassword");
  };
  const [values, setValues] = useState({
    email: "",
    password: "",
    device_id: id,
  });
  const handleInput = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    console.log(values.email);
    console.log(values.password);
  };
  function handleValidation(e) {
    e.preventDefault();
    const validationErrors = ValidationLogin(values);
    setErrors(validationErrors);
    console.log(values);
    const noErrors = Object.keys(validationErrors).length === 0;
    if (noErrors) {
      handleLogin();
    }
  }
  return (
    <div className="login-page">
      {id && (
        <div>
          <h2>Login Page for ID: {id}</h2>
        </div>
      )}
      <img src={GolfLogo} alt="golfLogo" className="golfLogo" />
      <img src={GolfLogoIpad} alt="golfLogo" className="logo-ipad ipad" />
      <h2 className="login-title mobile">登入</h2>
      <h2 className="login-title ipad">高擬真多人異地互動高球系統</h2>
      <div className="wrapper">
        <form className="inputfields-wrapper" onSubmit={handleValidation}>
          <div
            className={`position-relative${
              errors.email ? " margin-bottom" : ""
            }`}
          >
            <Input
              value={values.email}
              onChange={handleInput}
              placeHolder="帳號"
              type="email"
              name="email"
              image={GolfBallIcon}
            />
            {errors.email && <p className="error-message">{errors.email}</p>}
          </div>
          <div
            className={`position-relative${
              errors.password ? " margin-bottom" : ""
            }`}
          >
            <Input
              value={values.password}
              onChange={handleInput}
              placeHolder="密碼"
              type="password"
              name="password"
              isPassword
              image={KeyIcon}
            />
            {errors.password && (
              <p className="error-message">{errors.password}</p>
            )}
          </div>
          <div className="third-party-login-buttons-wrapper ">
            <button
              className="third-party-login-button google"
              onClick={google}
              type="button"
            >
              <img src={Google} alt="" className="icon" />
            </button>
            <button
              className="third-party-login-button linecss"
              onClick={line}
              type="button"
            >
              <img src={Line} alt="" className="icon" />
            </button>
            <button
              className="third-party-login-button facebook"
              onClick={facebook}
              type="button"
            >
              <img src={Facebook} alt="" className="icon" />
            </button>
          </div>

          <Button text="登入" type="submit" />
        </form>
        <Button onClick={navigateSignUp} text="註冊" isTransparent />
        {/* <button
          className="forgot-password-button"
          onClick={navigateForgotPassword}
        >
          忘記密碼
        </button>

        <div>
          <button onClick={navigateNewPassword}>new password</button>
          <button onClick={fetchData}>Fetch Data</button>
        </div> */}
      </div>
      {isOpen && status === true && (
        <Popup
          title="登入成功"
          image={SuccessIcon}
          onClick={navigateLogin}
          handleClose={togglePopup}
        />
      )}
      {isOpen && status === false && (
        <Popup
          title="登入失敗"
          image={ErrorIcon}
          onClick={navigateLogin}
          handleClose={togglePopup}
        />
      )}
    </div>
  );
};

export default Login;

function ValidationLogin(values) {
  console.log("validation values" + values);
  let error = {};

  if (values.email === "") {
    error.email = "*此欄為必填欄位";
  }

  if (values.password === "") {
    error.password = "*此欄為必填欄位";
  }

  return error;
}

export default ValidationLogin;

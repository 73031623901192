import React from "react";

import Button from "../../atoms/Button";
const Popup = (props) => {
  const { onClick, title, image, handleClose } = props;
  return (
    <div className="popup-box">
      <div className="box">
        <h2 className="popup-title">{title}</h2>
        <img src={image} alt="resulticon" />
        <Button onClick={onClick} text="OK" />
        <div style={{ marginBottom: "58px" }} />
      </div>
    </div>
  );
};

export default Popup;

import React from "react";
import Header from "../atoms/Header";
import QRcode from "../img/qr.png";

const Home = () => {
  return (
    <div className="home-page">
      <Header text="請重新掃描QR-Code" />
      <div className="qr-border">
        <img src={QRcode} />
        <p className="qr-text">請重新掃描QR-Code</p>
      </div>
    </div>
  );
};

export default Home;

import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Popup from "../../components/Popup/Popup";
import Input from "../../atoms/Input";
import Button from "../../atoms/Button";
import EmailIcon from "../../img/icon_button/email.png";
import axiosInstance from "../../utils/axiosConfig";
import SuccessIcon from "../../img/icon_button/ok.png";
import ErrorIcon from "../../img/icon_button/no.png";
import ValidationEmail from "../../utils/ValidationEmail";
const EnterEmail = () => {
  const [email, setEmail] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");

  const isEmail = useRef(false);
  const handleInput = (e) => {
    setEmail(e.target.value);
    console.log(email);
  };
  const headers = {
    "Content-Type": "application/json",
  };
  const handleEmailCheck = async () => {
    try {
      const response = await axiosInstance.get(`forget_password/${email}`);
      //console.log("Email checked:", response.data + isEmail);
      if (response.data.status === true) {
        isEmail.current = true;
        console.log(
          "Email checked:",
          JSON.stringify(response.data) + isEmail.current
        );
      } else if (response.data.status === false) {
        isEmail.current = false;
        console.log(
          "Email checked:",
          JSON.stringify(response.data) + isEmail.current
        );
      }
      togglePopup();
    } catch (error) {
      console.error("Error checking email:", error);
      // setIsEmail(false);
      // togglePopup();
    }
  };
  function handleValidation(e) {
    e.preventDefault();
    const validationErrors = ValidationEmail(email);
    setError(validationErrors);
    console.log(email);
    const noErrors = Object.keys(validationErrors).length === 0;
    if (noErrors) {
      handleEmailCheck();
    }
    // setIsEmail(!isEmail);
  }
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const navigate = useNavigate();
  const login = () => {
    const id = sessionStorage.getItem("device_id");
    navigate(`/login/${id}`);
  };
  return (
    <div>
      <div className="enter-email-page">
        <h2 className="enter-email-title">請輸入註冊時的信箱</h2>
        <form
          className="register-form inputfields-wrapper"
          onSubmit={handleValidation}
        >
          <div className={`position-relative${error ? " margin-bottom" : ""}`}>
            <Input
              image={EmailIcon}
              placeHolder="example@mail.com"
              value={email}
              onChange={handleInput}
              name="email"
              type="email"
            />

            {error && <p className="error-message">{error}</p>}
          </div>

          <div style={{ marginTop: "54px", width: "100%" }} />
          <Button text="送出" type="submit" />
        </form>
      </div>
      {isOpen ? (
        <Popup
          title={
            isEmail.current ? (
              <b>更改密碼連網頁連結已寄至信箱</b>
            ) : (
              <b>信箱錯誤請重新填寫資料</b>
            )
          }
          onClick={isEmail.current ? login : togglePopup}
          image={isEmail.current ? SuccessIcon : ErrorIcon}
        />
      ) : null}
    </div>
  );
};

export default EnterEmail;

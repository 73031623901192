import "./app.css";
import Login from "./pages/Login";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import SignUp from "./pages/SignUp";
import EnterEmail from "./pages/ForgotPassword/EnterEmail";
import EnterNewPassword from "./pages/ForgotPassword/EnterNewPassword";
import { useSelector, useDispatch } from "react-redux";
import Loading from "./pages/Loading";
import Home from "./pages/Home";
const App = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const isLoading = useSelector((state) => state);
  const dispatch = useDispatch();
  const headers = {
    "Content-Type": "application/json",
  };
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  if (window.location.hash == "#_=_") {
    window.location.hash = "";
  }
  if (isLoading) {
    // Show the Loading component when isLoading is true
    return <Loading />;
  }

  return (
    <BrowserRouter>
      <div>
        {/* <Navbar user={user} /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login/:id" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgotpassword" element={<EnterEmail />} />
          <Route
            path="/newpassword/:id/:token"
            element={<EnterNewPassword />}
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;

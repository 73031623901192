import React from "react";
import LoadingIcon from "../img/loading.png";
import LoadingVideo from "../video/loop.mp4";
const Loading = () => {
  return (
    <div className="loading-page">
      <video className="loading-image" autoPlay muted loop>
        <source src={LoadingVideo} type="video/mp4" />
      </video>
    </div>
  );
};

export default Loading;

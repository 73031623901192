function ValidationSignUp(values) {
  console.log("validation values" + values);
  let error = {};
  const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const password_pattern = /^(?=.*\d)(?=.*[a-z])[a-zA-Z0-9]{6,}$/;
  if (values.email === "") {
    error.email = "*此欄為必填欄位";
  } else if (!email_pattern.test(values.email)) {
    error.email = "Email Didn't match";
  }
  if (values.name === "") {
    error.name = "*此欄為必填欄位";
  }

  if (values.password === "") {
    error.password = "*此欄為必填欄位";
  } else if (!password_pattern.test(values.password)) {
    error.password =
      "*您的密碼長度應至少6碼以上且必須包含至少1個英文字母與1個數字";
  }

  if (
    values.confirm_password === "" ||
    String(values.confirm_password) !== String(values.password)
  ) {
    console.log(values.confirm_password + "___" + values.password);
    error.confirm_password = "*請輸入與以上相同的密碼";
  }

  return error;
}

export default ValidationSignUp;

import React from "react";
import FormSignUp from "../components/Form/FormSignUp";
import Header from "../atoms/Header";
const SignUp = () => {
  return (
    <div className="signup-page">
      <Header text="註冊" />
      <FormSignUp />
    </div>
  );
};

export default SignUp;

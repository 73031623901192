import React, { useState } from "react";
import OpenEyeIcon from "../../img/icon_button/see.png";
import CloseEyeIcon from "../../img/icon_button/hide.png";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

const Input = (props) => {
  const {
    image,
    placeHolder,
    isPassword = false,
    value,
    onChange,
    type,
    name,
    label = "",
    isRequired = false,
  } = props;

  const [showText, setShowText] = useState(true);
  const [inputType, setInputType] = useState(type);

  const toggleInputType = () => {
    setInputType(showText ? "text" : "password");
    setShowText(!showText);
  };

  return (
    <div className="input-wrapper-parent">
      <label
        className={`form-label${
          isRequired && label !== "" ? " with-asterisk" : ""
        }`}
      >
        {label}
      </label>

      <div className="input-wrapper">
        <div className="image-container">
          <img src={image} alt="icon" />
        </div>
        {name === "birth_date" ? (
          <DatePicker
            className="date-picker custom-inputfield"
            dateFormat="dd/MM/yyyy"
            name="birth_date"
            onChange={onChange}
            value={value}
          />
        ) : (
          <input
            value={value}
            onChange={onChange}
            placeholder={placeHolder}
            name={name}
            className="custom-inputfield"
            type={inputType}
          />
        )}
        {isPassword && (
          <button
            type="button"
            onClick={toggleInputType}
            className="censor-button"
          >
            {!showText ? (
              <img src={OpenEyeIcon} alt="openeye" />
            ) : (
              <img src={CloseEyeIcon} alt="closeeye" />
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default Input;

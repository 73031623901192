import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ValidationSignUp from "../../utils/ValidationSignUp";
import Popup from "../Popup/Popup";
import Success from "../../img/success.png";

import Input from "../../atoms/Input";
import Button from "../../atoms/Button";
import Select from "react-dropdown-select";
import EmailIcon from "../../img/icon_button/email.png";
import KeyIcon from "../../img/icon_button/pass.png";
import UserIcon from "../../img/icon_button/user.png";
import BirthdayIcon from "../../img/icon_button/birthday.png";
import SuccessIcon from "../../img/icon_button/ok.png";
import ErrorIcon from "../../img/icon_button/no.png";
import axiosInstance from "../../utils/axiosConfig";
const FormSignUp = () => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    confirm_password: "",
    name: "",
    gender: -1,
    birth_date: "",
  });

  const [errors, setErrors] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const handleInput = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleDateChange = (date) => {
    setValues((prev) => ({ ...prev, birth_date: date }));
  };
  const handleRadioButtonChange = (event) => {
    const selectedGender = event.target.value;
    setValues((prev) => ({ ...prev, gender: selectedGender }));
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  function handleValidation(e) {
    e.preventDefault();
    const validationErrors = ValidationSignUp(values);
    setErrors(validationErrors);
    console.log(values);
    const noErrors = Object.keys(validationErrors).length === 0;
    if (noErrors) {
      handleRegister();
    }
  }
  const navigate = useNavigate();
  const navigateLogin = () => {
    const id = sessionStorage.getItem("device_id");
    navigate(`/login/${id}`);
  };
  const gender_options = [
    { label: "男", value: 0 },
    { label: "女", value: 1 },
    { label: "其他", value: 2 },
  ];
  const headers = {
    "Content-Type": "application/json",
  };
  const handleRegister = async () => {
    try {
      console.log(values);
      const registerData = {
        email: values.email,
        password: values.password,
        name: values.name,
        gender: values.gender,
      };
      const response = await axiosInstance.post(
        "account_register",
        registerData,
        {
          headers,
        }
      );
      console.log("Account created:", response.data);
      if (response.data.status === true) {
        setStatus(true);
      } else if (response.data.status === false) {
        setStatus(false);
      }
      togglePopup();
    } catch (error) {
      console.error("Error creating account:", error);
      setStatus(false);
      togglePopup();
    }
  };

  return (
    <div className="auth-form-container">
      <form
        className="register-form inputfields-wrapper"
        onSubmit={handleValidation}
      >
        <div style={{ width: "100%" }}>
          <div
            className={`position-relative${
              errors.email ? " margin-bottom" : ""
            }`}
          >
            <Input
              value={values.email}
              onChange={handleInput}
              type="email"
              name="email"
              placeHolder="example@mail.com"
              image={EmailIcon}
              label="信箱"
              isRequired
            />
            {errors.email && <p className="error-message">{errors.email}</p>}
          </div>
          <div
            className={`position-relative${
              errors.password ? " margin-bottom" : ""
            }`}
          >
            <Input
              value={values.password}
              onChange={handleInput}
              type="password"
              name="password"
              image={KeyIcon}
              isPassword
              label="密碼"
              isRequired
            />
            {errors.password && (
              <p className="error-message">{errors.password}</p>
            )}
          </div>
          <div
            className={`position-relative${
              errors.confirm_password ? " margin-bottom" : ""
            }`}
          >
            <Input
              value={values.confirm_password}
              onChange={handleInput}
              type="password"
              name="confirm_password"
              image={KeyIcon}
              isPassword
              isRequired
              label="再次輸入密碼"
            />
            {errors.confirm_password && (
              <p className="error-message">{errors.confirm_password}</p>
            )}
          </div>
          <div
            className={`position-relative${
              errors.name ? " margin-bottom" : ""
            }`}
          >
            <Input
              value={values.name}
              onChange={handleInput}
              type="text"
              name="name"
              image={UserIcon}
              label="姓名 / 暱稱"
              isRequired
            />
            {errors.name && <p className="error-message">{errors.name}</p>}
          </div>
          <div className="input-wrapper-parent">
            <label htmlFor="gender" className="form-label">
              性別
            </label>
            <div className="radio-wrapper">
              {gender_options.map((option) => (
                <label key={option.value} className="radio-label">
                  <span>{option.label}</span>
                  <input
                    type="radio"
                    name="gender"
                    value={option.value}
                    checked={values.gender == option.value}
                    onChange={handleRadioButtonChange}
                    className="radio-input"
                  />
                  <span className="radio-image"></span>
                </label>
              ))}
            </div>
          </div>

          <Input
            name="birth_date"
            onChange={handleDateChange}
            value={values.birth_date}
            image={BirthdayIcon}
            label="生日"
          />
        </div>
        <div style={{ paddingTop: "30px", width: "100%" }}>
          <Button text="註冊" type="submit" />
        </div>
      </form>
      {isOpen && status === true && (
        <Popup
          title="登入成功"
          image={SuccessIcon}
          onClick={navigateLogin}
          handleClose={togglePopup}
        />
      )}
      {isOpen && status === false && (
        <Popup
          title="登入失敗"
          image={ErrorIcon}
          onClick={navigateLogin}
          handleClose={togglePopup}
        />
      )}
    </div>
  );
};

export default FormSignUp;
